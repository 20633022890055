/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export enum CustomFieldFormType {
    TEXTAREA = "textarea",
    INPUT = "input",
    CHECKBOX = "checkbox",
    DATETIME = "datetime",
    DATE = "date",
    TIME = "time",
    CURRENCY = "currency",
    INFO = "info",
    SELECT = "select",
}

export type SelectChoise = {
    id?: number;
    name: string;
    deleted?: boolean;
};

export type CustomFieldOptions = {
    min_length?: number;
    max_length?: number;
    required?: boolean;
    checkbox_info?: string;
    default_select?: number;
    placeholder?: string;
    choises?: SelectChoise[];
};

export interface CustomFieldMetadatas extends Model {
    model: string;
    app_id: number;
    title: string;
    description?: string;
    is_enabled: boolean;
    form_type: CustomFieldFormType;
    options?: CustomFieldOptions;
}

export interface CustomFieldMetadatasAdd {
    id?: number;
    model: string;
    title: string;
    description?: string;
    is_enabled: boolean;
    form_type: CustomFieldFormType;
    options: CustomFieldOptions & { defaultSelectAdd: string };
}

export interface CustomFieldMetadatasPaginator extends Paginator {
    customFieldMetadata: CustomFieldMetadatas[];
}

export default class CustomFieldMetadataAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("customFieldMetadatas", "customFieldMetadata", "customFieldMetadatas", adapter);
    }

    public async getFieldList(model: string): Promise<CustomFieldMetadatas[]> {
        const { data } = await this._get<{ customFieldMetadata: CustomFieldMetadatas[] }>(
            `fields/${model}`
        );

        return data.customFieldMetadata;
    }

    public async getField(app_id: number, model: string): Promise<CustomFieldMetadatas> {
        const { data } = await this._get<{ customFieldMetadata: CustomFieldMetadatas }>(
            `field/${app_id}/${model}`
        );

        return data.customFieldMetadata;
    }

    public async addForm(form: CustomFieldMetadatasAdd): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`add`, form);

        return data.success;
    }

    public async deleteField(id: number, model: string): Promise<boolean> {
        const { data } = await this._delete<{ success: boolean }>(`delete/${id}/${model}`);

        return data.success;
    }
}
